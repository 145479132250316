/* Inter font pulled from google's font api */
@import url("https://fonts.googleapis.com/css?family=Inter:wght@1,100..900");

/* STYLING VARIABLES */

:root {
  font-size: 2.275vh;

  /* Header Variables */
  --header-height: 3.5vh;

  /* UI Square Button Variables */
  --ui-square-button-width: 4vh;
  --ui-square-button-radius: 1vh;
  --ui-background-color: rgba(0, 0, 0, 0.92);
  --ui-background-white: rgb(220 220 220 / 75%);
  --ui-text-color: rgb(255 255 255 / 75%);
  --ui-margin: 0.75vh;

  /* Left UI Variables */
  --left-ui-width: max(20vw, 250px);
  --left-ui-width-expanded: min(60vw, 2646px);
  /* --left-ui-width-expanded: min(70%, 1200px); */
  --left-ui-padding: 2vh;
  --transition-time: 750ms;

  /* Slider Variables */
  --slider-height: 1.25vh;
  --slider-container-height: calc(10.5vh - 3.16vh);
  --slider-right-padding: 4.42vh;
  --slider-info-box-height: calc(20vh - 3.16vh);

  --slider-red: #5b1010;
  --slider-light-red: #7e0505;
  --slider-border-red: #bf8282bf;
  --slider-angry-red: rgb(255 0 0);
  --slider-blue: #1385c3;
  --slider-border-blue: #89c2e1d8;
  --slider-gray: #292929;
  --slider-light-gray: #707070;
  --slider-info-gray: rgb(222 222 222 / 9%);

  /*Layer UI Variables */
  --layer-ui-width: max(15vw, 150px);
  --layer-ui-padding: 1.25vh;
  --layer-ui-layer-toggle-height: 1.9vh;

  /* Scenario UI Variables */
  --scenario-crumb-width: 1.5vh;
  --scenario-ui-width: min(25vw, calc(100vw - 400px));
  --scenario-crumb-color: rgb(165 165 165 / 100%);
  --scenario-trail-color: rgb(93 93 93 / 100%);

  /* Modal Variables */
  --modal-arrow-size: 1vh;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

/* BASE ELEMENT STYLING */

h1 {
  font-family: Inter;
  font-size: 1rem;
  font-variation-settings: "wght" 550;

  margin: 0 0 1.25vh;
}

h2 {
  font-family: Inter;
  font-size: 0.778rem;
  font-variation-settings: "wght" 400;
  margin-top: 1vh;
  margin-bottom: 1vh;
  line-height: 1rem;
}

h3 {
  font-family: Inter;
  font-size: 0.667rem;
  font-variation-settings: "wght" 300;
  color: var(--ui-text-color);

  position: absolute;
  top: 0;
  margin: 0;
}

p {
  font-family: Inter;
  font-size: 0.5rem;
  font-variation-settings: "wght" 400;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--ui-text-color);
  line-height: 0.75rem;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.load-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 4s 1s, visibility 4s;
}

.load-cover-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(125, 125, 255, 15%);
  opacity: 0;
  visibility: hidden;
  transition: height 0.5s, opacity 5s, visibility 4s;
}

/* POPUP STYLING */

.popup {
  background-color: var(--ui-background-color);
  border-radius: 0.625vh;
}

.popup-title {
  color: var(--ui-text-color);
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
}

.popup-subheader {
  position: relative;
}

.popup-body-child {
  margin-top: 0.5vh;
}

.popup-body-child:first-child {
  margin-right: 1vw;
}

.popup-body-child:last-child {
  max-height: 14vh;
  overflow: auto;
}

.popup-slider-container {
  width: 20vw;
  margin-top: 2vh;
}

::-webkit-scrollbar {
  width: 0.5vw;
}

::-webkit-scrollbar-track {
  background: var(--slider-light-gray);
  border-radius: 0.25vw;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0.25vw;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ui-text-color);
}

/* HOVER POPUP STYLING */

.hover-popup {
  background-color: rgb(0 0 0 / 75%);
  border-radius: 0.625vh;
}

.hover-text {
}

#hover-bold {
  color: white;
}

#hover-italics {
  font-style: italic;
}

.mapboxgl-popup-content {
  background-color: rgb(0 0 0 / 0%);
  box-shadow: none;
  padding: 0.75vh 1vh;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  color: white;
  font-size: 1rem;
}

/* MODALS */

.modal {
  position: absolute;
  background-color: var(--ui-background-color);
  padding: var(--modal-arrow-size);
  border-radius: 0.5vh;
  max-width: 20vw;
}

.modal.fade-in {
  animation: fadeInAnimation ease var(--transition-time);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.modal-arrow {
  position: absolute;
  border: solid var(--modal-arrow-size) transparent;
}

.modal-text {
  white-space: pre-line;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* HEADER */

.header-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background-color: var(--ui-background-color);
}

.header-title-icon {
  position: absolute;
  left: calc(var(--header-height) / 2);
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(0.5 * var(--header-height));
  height: calc(0.5 * var(--header-height));
}

.header-title {
  position: absolute;
  left: var(--header-height);
  top: 50%;
  transform: translateY(-50%);
  color: var(--ui-text-color);
  cursor: default;
}

.header-list {
  position: absolute;
  right: 0;
  height: 100%;
}

.header-list-item {
  display: inline-block;
  height: 100%;
  line-height: 0;
}

.header-list-divider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0.1vw;
  height: calc(0.5 * var(--header-height));
  background-color: var(--ui-text-color);
}

.header-button {
  position: relative;
  vertical-align: text-top;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(0 0 0 / 0%);
  color: var(--ui-text-color);
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
}

#header-button-active {
  color: white;
}

/* MENU BUTTONS */
.button-panel-left {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: calc(-1 * (var(--ui-square-button-width) + var(--ui-margin)));
  transition: left var(--transition-time);
}

.button-panel-right {
  position: absolute;
  top: var(--header-height);
  right: 0;
  margin-top: var(--ui-margin);
  margin-right: var(--ui-margin);
}

.button-panel-gap {
  width: 100%;
  height: var(--ui-margin);
}

.menu-button {
  height: var(--ui-square-button-width);
  width: var(--ui-square-button-width);
  background-color: var(--ui-background-white);
  border-top-left-radius: var(--ui-square-button-radius);
  border-top-right-radius: var(--ui-square-button-radius);
  border-bottom-left-radius: var(--ui-square-button-radius);
  border-bottom-right-radius: var(--ui-square-button-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#menu-button-clicked {
  background-color: var(--ui-background-color);
}

.menu-button-icon {
  width: 60%;
  height: 60%;
}

/* SCENARIO UI */

.scenario-ui {
  position: absolute;
  bottom: var(--header-height);
  right: calc(2 * var(--ui-margin));
  width: var(--scenario-ui-width);
  height: 50vh;
  /*background-image: linear-gradient(to right, rgb(0 0 0 / 0), var(--ui-text-color), var(--ui-text-color));*/
  background-color: var(--ui-text-color);
  border-radius: 0.625vh;
  padding: var(--layer-ui-padding) calc(2 * var(--layer-ui-padding));
  display: flex;
  transition: var(--transition-time);
}

#scenario-ui-collapsed {
  /*
  width: max(calc(var(--layer-ui-width) + 2 * var(--ui-margin)), 200px);
  */
  width: var(--scenario-ui-width);
}

.stages-container {
  position: absolute;
  width: calc(8 * var(--scenario-crumb-width));
  right: 0;
  margin-right: 2vh;
  height: 85%;
}

#stages-container-collapsed {
  flex: 0.5;
}

.stages-list {
  height: 100%;
}

.stage-container {
  position: relative;
}

.stage-name {
  position: absolute;
  left: calc(3.5 * var(--scenario-crumb-width));
  top: 50%;
  width: calc(100% - 3.5 * var(--scenario-crumb-width));
  transform: translateY(-50%);
  color: var(--ui-background-color);
}

.stage-crumb {
  position: absolute;
  top: 50%;
  left: var(--scenario-crumb-width);
  transform: translate(-50%, -50%);

  height: var(--scenario-crumb-width);
  width: var(--scenario-crumb-width);
  border-radius: 50%;
  background-color: var(--scenario-crumb-color);

  transition: var(--transition-time);
}

#crumb-active {
  height: calc(2 * var(--scenario-crumb-width));
  width: calc(2 * var(--scenario-crumb-width));
}

.stage-crumb-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -51%);
  height: 60%;
  width: 60%;
}

.stage-crumb-trail {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: var(--scenario-crumb-width);
  background-color: var(--scenario-trail-color);
  width: 0.25vh;
}

.scenario-content-container {
  height: 90%;
  width: calc(100% - 10 * var(--scenario-crumb-width));
  padding-right: calc(2 * var(--layer-ui-padding));
  display: flex;
  flex-direction: column;
}

.scenario-name {
  color: var(--ui-background-color);
  font-size: 1.25rem;
}

.scenario-description {
  overflow: auto;
  flex-grow: 1;
  color: var(--ui-background-color);
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.scenario-buttons {
  position: absolute;
  right: calc(2 * var(--layer-ui-padding));
  bottom: calc(var(--left-ui-padding) / 2);
  height: 10%;
  width: max(calc(var(--layer-ui-width) - 2 * var(--layer-ui-padding)), 175px);
  display: flex;
}

.scenario-button {
  flex: 1;
  transform: translateY(50%);
  height: 50%;
  border-radius: 0.5vh;
  margin: 0 0.5vw;
  background-color: var(--ui-background-color);
  text-align: center;
  cursor: pointer;
}

.scenario-explore-button-container {
  position: absolute;
  right: calc(2 * var(--layer-ui-padding));
  bottom: calc(var(--left-ui-padding) / 2 + 7.5%);
  height: 10%;
  width: calc(max(calc(var(--layer-ui-width) - 2 * var(--layer-ui-padding)), 175px) / 2);
}

.explore-button-text {
  padding: 5%;
}

#scenario-button-inactive {
  background-color: var(--scenario-crumb-color);
}

.scenario-button-icon {
  height: 100%;
}

/* LEFT UI STYLING */

/* slider ui container */
.left-ui {
  position: absolute;
  left: calc(-1 * var(--left-ui-width));
  height: calc(100% - 2 * var(--header-height) - 2 * var(--left-ui-padding));
  top: var(--header-height);
  width: var(--left-ui-width);
  background-color: var(--ui-background-color);
  color: var(--ui-text-color);
  border-radius: 0.625vh;
  box-shadow: 0 0 0.125vh rgb(255 255 255 / 75%);
  margin-top: var(--ui-margin);
  transition: all var(--transition-time);
}

/* expanded slider ui width*/
.left-ui.expanded {
  left: 0;
  width: var(--left-ui-width-expanded);
  padding: var(--left-ui-padding);
  padding-right: calc(var(--left-ui-padding) + var(--slider-right-padding));
}

.left-ui.open {
  left: 0;
  padding: var(--left-ui-padding);
  padding-right: calc(var(--left-ui-padding) + var(--slider-right-padding));
}

/* DATA UI STYLING */

.data-ui-container {
  width: calc(100% + var(--slider-right-padding));
  height: 100%;
}

.data-ui-line {
  position: relative;
  margin: 2.5vh 0;
}

.data-ui-line span {
  float: right;
  font-variation-settings: "wght" 600;
}

.streamflow-chart-container {
  height: 30vh;
}

/* ABOUT STYLING */

.eula-collapsible {
  background-color: var(--ui-text-color);
  color: var(--ui-background-color);
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  margin-top: var(--left-ui-padding);
}

.eula-collapsible.active,
.eula-collapsible:hover {
  background-color: var(--ui-background-white);
}

.eula-collapsible-text {
  padding: calc(var(--left-ui-padding) / 4) calc(var(--left-ui-padding) / 2);
  margin: 0;
}

.eula-content-container {
  height: 40%;
  padding: calc(var(--left-ui-padding) / 2);
  display: none;
  overflow: auto;
  background-color: var(--ui-text-color);
}

.eula-content-container.active {
  display: block;
}

.eula-content {
  color: var(--ui-background-color);
  white-space: pre-line;
}

/* SLIDER STYLING */

/* slider container */
/* ratio of water right to width of this container is what determines the conversion rate between
   flowrate units and screenspace */
.slider-container {
  position: relative;
  width: 100%;
  height: var(--slider-container-height);
  transition: width var(--transition-time), margin-left var(--transition-time);
}

/* css transitions are turned off when slider panel is expanded so that there isn't delay when
   adjusting sliders */
/* this selector is turned on if the slider menu is expanded and the transition time has elapsed */
.slider-container.expanded {
  transition: width 0ms, margin-left 0ms;
}

/* slider positioned in middle of container */
.slider {
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.scalar-slider-box {
  position: absolute;
  transform: translate(calc(var(--left-ui-width) + 3 * var(--left-ui-padding)), calc(-1.25vh - 100%));
  width: calc(100% - var(--left-ui-width) - 6 * var(--left-ui-padding) - var(--slider-right-padding));
  height: var(--slider-container-height);
  box-shadow: 0 0 0.25vh rgb(255 255 255 / 50%);
  border-radius: 0.625vh;
  margin-top: 1.25vh;
  padding-top: calc(var(--slider-container-height) / 4);
  padding-bottom: calc(var(--slider-container-height) * 0.9);
  padding-left: var(--left-ui-padding);
  padding-right: var(--slider-right-padding);
  transition: var(--transition-time);
}

.scalar-slider-container {
  position: relative;
  width: 100%;
  height: var(--slider-container-height);
  transition: width var(--transition-time), margin-left var(--transition-time);
}

.scalar-slider {
  top: calc(var(--slider-container-height) / 2);
  width: 100%;
}

.scalar-text {
  position: absolute;
  top: calc(-2.5 * var(--left-ui-padding));
  left: 0;
}

.scalar-info-text {
  position: absolute;
  margin-left: 2vw;
  top: 110%;
  height: calc(var(--slider-container-height) / 2);
  overflow: hidden;
}

.scalar-use-line {
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 0.075vw;
  height: calc(2 * var(--slider-height));
  background-color: var(--slider-blue);
}

.scalar-max-label {
  position: absolute;
  right: 0;
  bottom: 100%;
  width: var(--left-ui-width);
  color: var(--slider-light-gray);
}

.scalar-max-line {
  position: absolute;
  right: 0;
  bottom: 50%;
  width: 0.075vw;
  height: calc(2 * var(--slider-height));
  background-color: var(--slider-light-gray);
  text-align: right;
}

.scalar-use-label {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: var(--left-ui-width);
  color: var(--slider-blue);
}

/* slider track base styling */
.track {
  top: 50%;
  transform: translateY(-50%);
  height: var(--slider-height);
}

/* consumptive use track */
.track-0 {
  background-color: var(--slider-blue);
  border: 0.125vh solid var(--slider-light-gray);
  border-radius: var(--slider-height);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* track between consumptive use and water right */
.track-1 {
  background-color: var(--slider-gray);
  border: 0.125vh solid var(--slider-light-gray);
  border-radius: var(--slider-height);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* text displayed on slider tracks */
.track-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.5vh;
  z-index: 2;
}

/* block on right end of slider */
.track-water-right-block {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -0.125vh;
  height: calc(var(--slider-height) + 0.25vh);
  width: 0.5vw;
  background-color: var(--slider-light-gray);
}

/* line to water right text */
.track-water-right-line {
  position: absolute;
  bottom: 0;
  right: -0.125vh;
  height: calc(2 * var(--slider-height));
  width: 0.125vh;
  background-color: var(--slider-light-gray);
}

/* water right label */
.track-water-right-label {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 13vh;
  text-align: right;
  color: var(--slider-light-gray);
}

/* slider knob base styling */
.thumb {
  top: 50%;
  transform: translateY(-50%);
  width: calc(1.25 * var(--slider-height));
  height: calc(1.25 * var(--slider-height));
  border: 0.125vh solid var(--slider-border-blue);
  border-radius: 50%;
  background-color: var(--slider-blue);
  cursor: pointer;
  outline: none;
}

.thumb-knob-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
}

/* track for consumptive use overflow beyond allocation */
.thumb-allocation-overflow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  height: var(--slider-height);
  background-color: var(--slider-red);
  cursor: default;
  pointer-events: none;
}

/* allocation thumb container holding knob and line */
.thumb-allocation {
  top: 50%;
  transform: translateY(-50%);
  height: var(--slider-height);
  width: 0.25vw;
  cursor: pointer;
  outline: none;
}

/* allocation knob line */
.thumb-allocation-line {
  position: absolute;
  bottom: top;
  right: 0;
  height: calc(1.5 * var(--slider-height));
  width: 0.1vw;
  background-color: var(--slider-light-red);
}

/* allocation knob */
.thumb-allocation-knob {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(1.25 * var(--slider-height));
  height: calc(1.25 * var(--slider-height));
  border: 0.125vh solid var(--slider-border-red);
  border-radius: 50%;
  background-color: var(--slider-light-red);
  cursor: pointer;
  outline: none;
}

/* allocation knob label */
.thumb-allocation-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 0.5vw;
  cursor: pointer;
}

/* mark showing initial allocation */
.mark {
  top: 50%;
  transform: translateY(-50%);
  height: calc(var(--slider-height) + 0.125vh);
  width: 0.25vw;
  background-color: var(--slider-light-red);
  z-index: 1;
}

.mark-total-volume {
  top: 50%;
  transform: translate(1.5vh, -50%);
  height: calc(var(--slider-height) + 0.125vh);
  width: 0.25vw;
  background-color: #f1b855;
  z-index: 1;
}

.allocation-warning-icon {
  position: absolute;
  height: 1.5vh;
  top: 150%;
  transform: translate(110%, 20%);
}

/* INFO BOX */

/* slider info box container */
.slider-info-box {
  width: calc(var(--left-ui-width) - 2 * var(--left-ui-padding));
  height: var(--slider-container-height);
  background-color: var(--slider-info-gray);
  border-radius: 0.625vh;
  margin-top: 1.25vh;
  padding-top: calc(var(--slider-container-height) / 4);
  padding-bottom: calc(var(--slider-container-height) * 0.9);
  padding-left: var(--left-ui-padding);
  padding-right: calc(var(--left-ui-padding) + var(--slider-right-padding));
}

/* slider info consumptive use line */
.slider-info-use-line {
  position: absolute;
  left: 1vw;
  bottom: calc(50% + (var(--slider-height) / 2) + 0.125vh);
  width: 0.075vw;
  height: calc(1.5 * var(--slider-height) - (var(--slider-height) / 2) - 0.125vh);
  background-color: var(--slider-blue);
}

/* slider info consumptive use label */
.slider-info-use-label {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: var(--left-ui-width);
  color: var(--slider-blue);
}

/* slider info i icon */
.slider-info-icon {
  width: 2.125vh;
  height: 2.125vh;
  position: absolute;
  top: 110%;
}

/* slider info i icon background */
.slider-info-icon-background {
  position: absolute;
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  top: calc(110% + 0.125vh);
  margin-left: 0.125vh;
  background-color: var(--slider-light-red);
}

/* slider info box information text */
.slider-info-text {
  position: absolute;
  margin-left: 2vw;
  top: 140%;
  transform: translateY(-50%);
}

/* LAYERS PANEL STYLING */

/* layers ui panel container */
.layers-ui {
  position: absolute;
  top: var(--header-height);
  right: calc(2 * var(--ui-margin) + var(--ui-square-button-width));
  width: calc(var(--layer-ui-width) / 2);
  background-color: var(--ui-background-color);
  border-radius: 0.625vh;
  margin-top: var(--ui-margin);
  min-height: 25vh;
  transform-origin: 100% 50%;
  transform: scaleX(00);
  transition: all var(--transition-time);
}

.layers-ui.open {
  transform: scaleX(1);
  width: var(--layer-ui-width);
  padding: var(--layer-ui-padding);
}

/* layers panel layer name, icon, and switch container */
.layer-container {
  position: relative;
  width: 100%;
  height: var(--layer-ui-layer-toggle-height);
}

#layer-subcategory {
  left: calc(var(--layer-ui-layer-toggle-height) + 0.25vh);
}

/* layer ui icon */

.layer-ui-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: calc(var(--layer-ui-layer-toggle-height) * 0.75);
  width: calc(var(--layer-ui-layer-toggle-height) * 0.75);
}

/* layer ui title */
.layer-ui-title {
  margin-bottom: 0.625vh;
}

/* layer ui text */
.layer-ui-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: calc(var(--layer-ui-layer-toggle-height) + 0.25vh);
  text-transform: uppercase;
  font-size: 0.4rem;
}

#category-text {
  font-variation-settings: "wght" 600;
}

/* switch container */
.switch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: inline-block;
  width: calc(var(--layer-ui-layer-toggle-height) * 1.5);
  height: calc(var(--layer-ui-layer-toggle-height) * 0.75);
}

/* hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* switch knob */
.switch-knob {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: var(--layer-ui-layer-toggle-height);
}

/* unchecked knob */
.switch-knob:before {
  position: absolute;
  content: "";
  height: calc(var(--layer-ui-layer-toggle-height) * 0.75);
  width: calc(var(--layer-ui-layer-toggle-height) * 0.75);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

/* checked knob */
input:checked + .switch-knob {
  background-color: var(--slider-blue);
}

input:focus + .switch-knob {
  box-shadow: 0 0 0.125vh var(--slider-blue);
}

input:checked + .switch-knob:before {
  -webkit-transform: translateX(0.625vh);
  -ms-transform: translateX(0.625vh);
  transform: translate(calc(var(--layer-ui-layer-toggle-height) / 1.5), -50%);
}

.seniority-slider {
  height: var(--slider-height);
  padding-top: var(--layer-ui-padding);
  margin: 0 calc(2 * var(--layer-ui-padding));
}

.track-seniority {
  top: 50%;
  transform: translateY(-50%);
  height: var(--slider-height);
  background-color: var(--slider-gray);
  border: 0.125vh solid var(--slider-light-gray);
  border-radius: var(--slider-height);
}

.track-seniority-text-0 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-110%, -50%);
}

.track-seniority-text-1 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(110%, -50%);
}

.thumb-seniority-text-0 {
  position: absolute;
  top: 50%;
  right: calc(1.5 * var(--slider-height));
  transform: translate(0, -50%);
}

.thumb-seniority-text-1 {
  position: absolute;
  top: 50%;
  left: calc(1.5 * var(--slider-height));
  transform: translate(0, -50%);
}
